import { configureStore } from "@reduxjs/toolkit";
import configSlice from "./slices/configSlice";

const store = configureStore({
    reducer: {
        configs: configSlice
    }
})

export default store;
