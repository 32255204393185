import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { countryCode, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import { LoadingButton } from '@mui/lab';
import { AdminPanelSettings } from '@mui/icons-material';
import moment from 'moment';
import { useCommonUI } from '../context/UI';

function UserPage() {

    const {fetchData, postData} = useApiRequest();
    const {openSessions} = useCommonUI();
    const [userList, setUserList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [userModal, setUserModal] = useState(false);
    const [userId, setuserId] = useState(null);
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userType, setUserType] = useState(true);
    const [userStatus, setUserStatus] = useState(true);
    const [userPassword, setUserPassword] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setUserList([]);
        const resp = await fetchData('user/list');
        if(resp){
            setUserList(resp.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const addUser = () =>{
        setuserId(null);
        setUserName('');
        setUserPhone('');
        setUserType('');
        setUserPassword('');
        setUserStatus(true);
        setUserModal(true);
    }

    const editUser = (user) =>{
        setuserId(user.id);
        setUserName(user.name);
        setUserPhone(user.phone);
        setUserType(user.type);
        setUserStatus(user.status);
        setUserPassword('');
        setUserModal(true);
    }

    const closeUserModal = () =>{
        if(isSubmitting){
            return;
        }
        setUserModal(false);
    }

    const submitUser = async() =>{
        let endpoint = "user/add";
        if(userId){
            endpoint = "user/edit/"+userId;
        }
        if(userName.trim() === "" || userPhone === "" || userType === ""){
            return;
        }
        let obj = {
            name: userName.trim(),
            phone: userPhone,
            status: userStatus,
            type: userType,
            password: userPassword
        }

        setIsSubmitting(true);
        const resp = await postData(endpoint, obj);
        if(resp){
            setUserModal(false);
            getOneData();
        }
        setIsSubmitting(false);
    }

    const manageSession = (uid, type) => {
        openSessions(uid, type);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Users</Typography>
                <Button variant='contained' onClick={addUser} >Add User</Button>
            </Stack>

            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='20%'>
                    <Typography><b>Joined</b></Typography>
                </Box>
                <Box width='20%' paddingX={1}>
                    <Typography><b>Name</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Phone</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Type</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Status</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Session</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Edit</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 220px)' overflow='auto' gap={1}>
                {userList.map((user, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='20%'>
                            <Typography>{moment(user.createdAt).format('Do MMM YYYY h:mm a')}</Typography>
                        </Box>
                        <Box width='20%' paddingX={1}>
                            <Typography>{user.name}</Typography>
                        </Box>
                        <Box width='15%'>
                            <Typography>{user.phone}</Typography>
                        </Box>
                        <Box width='15%'>
                            <Typography>{user.type.toUpperCase()}</Typography>
                        </Box>
                        <Box width='10%'>
                            {user.status && <Typography color={'green'}>Active</Typography>}
                            {!user.status && <Typography color={'red'}>Inactive</Typography>}
                        </Box>
                        <Box width='10%'>
                            <Button variant='contained' color='warning' onClick={()=>{manageSession(user.id, user.type)}}>Manage</Button>
                        </Box>
                        <Box width='10%'>
                            <Button variant='contained' color='error' onClick={()=>{editUser(user)}} >Edit</Button>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Users</Typography>
                </Stack>}
                {userList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Users to show</Typography>
                </Stack>}
            </Stack>

            <Modal open={userModal} onClose={closeUserModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{userId?'Edit':'Add'} User</Typography>
                        <TextField label="User Name" value={userName} onChange={(e)=>{setUserName(e.target.value)}} variant="outlined" fullWidth/>

                        <TextField label="User Phone" type='number' value={userPhone} onChange={(e)=>{setUserPhone(e.target.value)}} variant="outlined" fullWidth InputProps={{inputProps: {min: 0}, startAdornment: <InputAdornment position='start'>{countryCode}</InputAdornment>}}/>

                        <Box>
                            <TextField label="Password" name='password' value={userPassword} onChange={(e=>{setUserPassword(e.target.value)})} variant="outlined" fullWidth />
                            {userId && <Typography fontSize={12}><i>Keep blank if not changed</i></Typography>}
                        </Box>

                        <FormControl fullWidth>
                            <InputLabel>User Type</InputLabel>
                            <Select value={userType} onChange={(e)=>{setUserType(e.target.value)}} variant="outlined" disabled={userId?true:false}>
                                <MenuItem value={'admin'}>Admin</MenuItem>
                                <MenuItem value={'telecaller'}>Telecaller</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel>User Status</InputLabel>
                            <Select value={userStatus} onChange={(e)=>{setUserStatus(e.target.value)}} variant="outlined">
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>

                        <LoadingButton fullWidth onClick={submitUser} variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<AdminPanelSettings/>}><span>{userId?'Update':'Add'} User</span></LoadingButton>
                    </Stack>
                </Box>
           </Modal>

        </Stack>
    )
}

export default UserPage