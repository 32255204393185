import { Check, DeleteForever, HighlightOff } from "@mui/icons-material";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Modal, Snackbar, Stack, Typography } from "@mui/material";
import { createContext, useCallback, useContext, useState } from "react";
import parse from 'html-react-parser';
import { ClipLoader } from "react-spinners";
import moment from "moment";
import axios from "axios";
import { apiUrl } from "../store/Common";
import { toast } from "react-toastify";

const UIContext = createContext();

export const useCommonUI = () =>{
    return useContext(UIContext);
}

export const UIProvider = ({children}) => {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [alertType, setAlertType] = useState('success');

    const [onConfirmAction, setOnConfirmAction] = useState(null);
    const [confirmActionParam, setConfirmActionParam] = useState(null); 
    const [dialogOpen, setDialogOpen] = useState(false); 
    const [dialogTitle, setdialogTitle] = useState(''); 
    const [dialogMessage, setdialogMessage] = useState(''); 

    const [sessionModal, setSessionModal] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const openSnackbar = useCallback((message, type) => {
        setSnackbarMessage(message);
        setAlertType(type);
        setSnackbarOpen(true);
    }, []);
    const closeSnackbar = () => {
        setSnackbarOpen(false);
        setSnackbarMessage('');
    };

    const openDialog = useCallback((title, message, onConfirm, param) => {
        setdialogTitle(title);
        setdialogMessage(message);
        setOnConfirmAction(() => onConfirm);
        setConfirmActionParam(param);
        setDialogOpen(true);
    }, []);
    const confirmDialog = () => {
        closeDialog();
        if (onConfirmAction) {
          onConfirmAction(confirmActionParam);
        }
    };
    const closeDialog = () => {
        setDialogOpen(false);
        setdialogTitle('');
        setdialogMessage('');
    };

    const openSessions = async(user, type) =>{
        setSessionList([]);
        setSessionModal(true);
        setIsLoading(true);
        try{
            const response = await axios.post(apiUrl+"app/sessions", {user, type}, {headers: {"x-session": localStorage.getItem('session')}});
            if(!response.data.status){
                throw new Error(response.data.message)
            }
            setSessionList(response.data.data);
        }catch(e){
            openSnackbar(e.message, "error");
        }finally{
            setIsLoading(false);
        }
    }

    const closeSessionModal = () =>{
        if(isLoading){
            return;
        }
        setSessionModal(false);
    }

    const deleteSession = async(sid) =>{
        const tid = toast.loading('Deleting Session',{position:'bottom-right'});
        setSessionModal(false);
        try{
            const response = await axios.get(apiUrl+"app/session/delete/"+sid, {headers: {"x-session": localStorage.getItem('session')}});
            if(!response.data.status){
                throw new Error(response.data.message)
            }
            toast.update(tid, { render: "Session Deleted", type: "success", isLoading: false, autoClose: 2000 });
        }catch(e){
            openSnackbar(e.message, "error");
            toast.dismiss();
        }
    }

    return (
        <UIContext.Provider value={{openSnackbar, openDialog, openSessions}}>
            {children}

            {/* Snackbar component */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={closeSnackbar} severity={alertType} sx={{ width: '100%' }} variant='filled'>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* Dialog component */}
            <Dialog open={dialogOpen} onClose={closeDialog}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {parse(dialogMessage)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} variant="outlined" color="primary" autoFocus startIcon={<HighlightOff/>}>Cancel</Button>
                    <Button onClick={confirmDialog} variant="outlined" color="error" startIcon={<Check/>}>Confirm</Button>
                </DialogActions>
            </Dialog>

            
            <Modal open={sessionModal} onClose={closeSessionModal}>
                <Box sx={{position: 'absolute',top: '10%', bottom: '10%', overflowX: 'auto', left: 'calc(50% - 450px)',width: 900,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Typography variant='h6' textAlign={'center'} mb={2}>Active Sessions</Typography>
                    {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Active Sessions</Typography>
                    </Stack>}
                    {!isLoading && sessionList.length === 0 && <Typography textAlign={'center'} mb={2}>No Active Sessions</Typography>}
                    <Stack gap={3}>
                        {sessionList.map((session)=>{
                            return <Stack key={session.id} sx={{padding: 2, background: '#f1f1f1', borderRadius: 3, flexDirection: 'row', alignItems: 'center', gap: 2}}>
                                <Stack flex={1}>
                                    <Typography variant='body'><b>Date: </b>{moment(session.created).format('Do MMM YYYY h:mm a')}</Typography>
                                    <Typography variant='body'><b>Device: </b>{session.device}</Typography>
                                </Stack>
                                <Stack>
                                    <IconButton color='error' onClick={()=>{deleteSession(session.id)}}>
                                        <DeleteForever/>
                                    </IconButton>
                                </Stack>
                            </Stack>
                        })}
                    </Stack>
                </Box>
            </Modal>

        </UIContext.Provider>
    )
}