import React, { useEffect, useState } from 'react'
import { useApiRequest } from '../store/Common';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { SearchTwoTone } from '@mui/icons-material';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { BlackColor, ColoredColor, WhiteColor } from '../components/color';

function StatusPage() {

    const {fetchData} = useApiRequest();
    const [isLoadingStore, setIsLoadingStore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStore, setSelectedStore] = useState('');
    const [storeList, setStoreList] = useState([]);
    
    const [orders, setOrders] = useState([]);
    const [ordersView, setOrdersView] = useState([]);
    const [detailsModal, setDetailsModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(()=>{
        const getStore = async() =>{
            setIsLoadingStore(true);
            const resp1 = await fetchData('store/listprocessing');
            if(resp1){
                setStoreList(resp1.data);
                setIsLoadingStore(false);
            }
        }
        getStore();
    },[fetchData]);

    const submitStore = (e) =>{
        e.preventDefault();
        getData(selectedStore);
    }

    const getData = async(storeId) =>{
        setIsLoading(true);
        setOrders([]);
        setOrdersView([]);
        const resp = await fetchData('order/active/'+storeId);
        if(resp){
            setOrders(resp.data);
            setOrdersView(resp.data);
        }
        setIsLoading(false);
    }

    const openModal = (order) => {
        setSelectedOrder(order);
        setDetailsModal(true);
    }

    const handleSearch = (e) =>{
        let searchQuery = e.target.value;
        setSearchTerm(e.target.value);
        if(searchQuery === ""){
            setOrdersView(orders);
        }else{
            setOrdersView(orders.filter(item => 
                item.orderId.toLowerCase().includes(searchQuery.toLowerCase())
            ));
        }
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Typography variant='h4'>Active Orders ({orders.length})</Typography>
            {storeList.length > 0 && <Box component='form' p={2} my={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onSubmit={submitStore}>
                <Stack flexDirection='row' gap={2}>
                    <FormControl fullWidth>
                        <InputLabel>Processing Center</InputLabel>
                        <Select value={selectedStore} onChange={(e)=>{setSelectedStore(e.target.value)}} >
                            {storeList.map(store=>{
                                return <MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>}
            {isLoadingStore && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                <ClipLoader size={15} color='#000' />
                <Typography variant='body'>Loading Processing Center List</Typography>
            </Stack>}
            {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                <ClipLoader size={15} color='#000' />
                <Typography variant='body'>Loading Active Orders</Typography>
            </Stack>}
            
            {orders.length > 0 && <Stack my={2} sx={{background: '#fff', borderRadius: 5, padding: 2}}>
                <TextField fullWidth variant='outlined' label='Order Id' value={searchTerm} onChange={handleSearch} />
            </Stack>}
            <Stack style={{flexDirection: 'row', gap: 10, flexWrap: 'wrap'}}>
                {ordersView.map((order)=>{
                    return <Stack key={order._id} p={2} sx={{minWidth: '200px', background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)', cursor: 'pointer'}} onClick={()=>{openModal(order)}}>
                        <Typography variant='h6' sx={{color: '#d9166f'}}><b>{order.orderId}</b></Typography>
                        <Typography variant='body'>{order.user.name}</Typography>
                        <Typography variant='body'><b><i>{order.status}</i></b></Typography>
                        <Typography variant='subtitle'>{moment(order.created).format('Do MMMM YYYY h:mm a')}</Typography>
                        <Typography variant='subtitle'>Items: <b>{order.totalItems}</b></Typography>
                        <Typography variant='subtitle' sx={{color: '#669c63'}}>Preparing: <b>{order.preparing}</b></Typography>
                        <Typography variant='subtitle' sx={{color: '#F75808'}}>Washing: <b>{order.washing}</b></Typography>
                        <Typography variant='subtitle' sx={{color: '#d11ae5'}}>Ironing: <b>{order.ironing}</b></Typography>
                        <Typography variant='subtitle' sx={{color: '#1d41e9'}}>Packing: <b>{order.packing}</b></Typography>
                        <Link to={`/orders/${order._id}`}>
                            <Button size='small' variant='contained'>Details</Button>
                        </Link>
                    </Stack>
                })}
            </Stack>

            <Modal open={detailsModal} onClose={()=>{setDetailsModal(false)}}>
                <Box sx={{bgcolor: 'background.paper', borderRadius: 5, position: 'absolute', top: '10%', left: '10%', right: '10%', maxHeight: '80%', overflowY: 'auto'}} padding={2}>
                    {selectedOrder && <Stack>
                        <Typography variant='h6' sx={{color: '#d9166f'}}><b>{selectedOrder.orderId}</b></Typography>
                        <Typography variant='body'>{selectedOrder.user.name}</Typography>
                        <Typography variant='body'><b><i>{selectedOrder.status}</i></b></Typography>
                        <Typography variant='body'>Order Date: {moment(selectedOrder.created).format('Do MMMM YYYY h:mm a')}</Typography>
                        <Typography variant='subtitle'>Items: <b>{selectedOrder.totalItems}</b></Typography>
                    </Stack>}
                    {selectedOrder && <Stack sx={{flexDirection: 'row', gap: 2, flexWrap: 'wrap', marginTop: 2}}>
                        {selectedOrder.items.map((orderItem, index)=>{
                            let qty = orderItem.qty?` (${orderItem.qty} ${orderItem.service.unit})`:'';
                            let bgColor = '#efefef';
                            let fontColor = '#000';
                            if(orderItem.status === "Washing"){
                                bgColor = '#F75808';
                                fontColor = '#fff';
                            }else if(orderItem.status === "Ironing"){
                                bgColor = '#6FF00F';
                            }else if(orderItem.status === "Dry Cleaning"){
                                bgColor = '#37C8B0';
                            }else if(orderItem.status === "Packing"){
                                bgColor = '#1D41E9';
                                fontColor = '#fff';
                            }
                            return <Stack key={orderItem.id} p={2} sx={{background: bgColor, borderRadius: 2, alignItems: 'center', justifyItems: 'center', boxShadow: '5px 3px 5px #00000005', color: fontColor}}>
                                <Typography variant='h5'><b>{orderItem.name}{qty}</b></Typography>
                                <Stack sx={{flexDirection: 'row', gap: 0.5, justifyContent: 'center', alignItems: 'center'}}>
                                    {orderItem.color === 'white' && <WhiteColor/>}
                                    {orderItem.color === 'black' && <BlackColor/>}
                                    {orderItem.color === 'color' && <ColoredColor/>}
                                    <Typography variant='body' textTransform='capitalize'>{orderItem.color}</Typography>
                                </Stack>
                                <Typography variant='body'>{index + 1} / {selectedOrder.items.length}</Typography>
                                <Typography variant='h6'>{orderItem.service.name}</Typography>
                                <Typography variant='h6'><b><i>{orderItem.status}</i></b></Typography>
                                <Typography variant='caption'>{orderItem.trackCode}</Typography>
                            </Stack>
                        })}
                    </Stack>}

                </Box>
            </Modal>

        </Stack>
    )
}

export default StatusPage