import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
    name: 'configSlice',
    initialState: {
        loggedIn: null,
        userName: '',
        userPhone: '',
        type: '',
    },
    reducers: {  
        updateLogin(state, action){
            state.loggedIn = action.payload
        },
        updateName(state, action){
            state.userName = action.payload
        },
        updatePhone(state, action){
            state.userPhone = action.payload
        },
        updateType(state, action){
            state.type = action.payload
        }
    }
})

export const {updateLogin, updateName, updatePhone, updateType} = configSlice.actions;
export default configSlice.reducer;