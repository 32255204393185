import React, { useCallback, useEffect, useState } from 'react'
import { useApiRequest } from '../store/Common';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { useCommonUI } from '../context/UI';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

function DeleteRequestPage() {

    const {fetchData} = useApiRequest();
    const {openSnackbar,openDialog} = useCommonUI();
    const [deleteList, setDeleteList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);
    const [isCompleting, setIsCompleting] = useState(false);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setDeleteList([]);
        const resp1 = await fetchData('deleterequest/lists');
        if(resp1){
            setDeleteList(resp1.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const completeRequest = (item) =>{
        if(isCompleting){
            openSnackbar("Wait for complete", "warning");
            return;
        }
        openDialog("Confirm Complete Delete Request?","Requested By: "+item.user.name, confirmComplete, item.id);
    }

    const confirmComplete = async(id) =>{
        const tid = toast.loading('Completing Request',{position:'bottom-right'});
        setIsCompleting(true);
        const resp = await fetchData('deleterequest/complete/'+id);
        if(resp){
            toast.update(tid, { render: "Request Completed", type: "success", isLoading: false, autoClose: 2000 });
            getOneData(1);
        }else{
            toast.dismiss();
        }
        setIsCompleting(false);
    }
    
    const removeRequest = (item) =>{
        if(isDeleting){
            openSnackbar("Wait for remove to complete", "warning");
            return;
        }
        openDialog("Remove Delete Request?","Requested By: "+item.user.name, confirmDelete, item.id);
    }

    const confirmDelete = async(id) =>{
        const tid = toast.loading('Removing Request',{position:'bottom-right'});
        setIsDeleting(true);
        const resp = await fetchData('deleterequest/remove/'+id);
        if(resp){
            toast.update(tid, { render: "Request Removed", type: "success", isLoading: false, autoClose: 2000 });
            getOneData(1);
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Delete Requests</Typography>
            </Stack>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='15%'>
                    <Typography><b>Created</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Name</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Phone</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Completed</b></Typography>
                </Box>
                <Box width='20%'>
                    <Typography><b>Completed On</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Mark as</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Delete</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 220px)' overflow='auto' gap={1}>
                {deleteList.map((data, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='15%'>
                            <Typography>{moment(data.created).format('Do MMMM YYYY h:mm a')}</Typography>
                        </Box>
                        <Box width='15%'>
                            <Link to={`/customers/${data.user.id}`}>
                                <Typography color={'rgb(71, 85, 105)'}>{data.user.name}</Typography>
                            </Link>
                        </Box>
                        <Box width='10%'>
                            <Typography>{data.user.phone}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{data.completed?'YES':'NO'}</Typography>
                        </Box>
                        <Box width='20%'>
                            <Typography>{data.completedOn?moment(data.completedOn).format('Do MMMM YYYY h:mm a'):''}</Typography>
                        </Box>
                        <Box width='15%'>
                            <Button variant='contained' color='success' onClick={()=>{completeRequest(data)}}>Mark as Completed</Button>
                        </Box>
                        <Box width='15%'>
                            <Button variant='contained' color='error' onClick={()=>{removeRequest(data)}}>Remove Request</Button>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Delete Requests</Typography>
                </Stack>}
                {deleteList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Delete Requests to show</Typography>
                </Stack>}
            </Stack>

        </Stack>
    )
}

export default DeleteRequestPage