import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners';
import { useApiRequest } from '../store/Common';
import { CleaningServices, LocalAtm } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';

function ServicePage() {
    
    const {fetchData, postData} = useApiRequest();

    const [states, setStates] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [serviceId, setServiceId] = useState(null);
    const [serviceName, setServiceName] = useState('');
    const [serviceLogo, setServiceLogo] = useState('');
    const [serviceType, setServiceType] = useState('kg');
    const [serviceUnit, setServiceUnit] = useState('');
    const [serviceStatus, setServiceStatus] = useState(false);

    const [serviceModal, setServiceModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [prices, setPrices] = useState({});
    const [priceId, setPriceId] = useState(null);
    const [priceName, setPriceName] = useState(false);
    const [priceModal, setPriceModal] = useState(false);
    const [isSubmittingPrice, setIsSubmittingPrice] = useState(false);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setServiceList([]);
        const resp = await fetchData('service/list');
        const resp1 = await fetchData('app/states');
        if(resp){
            setServiceList(resp.data);
        }
        if(resp1){
            setStates(resp1.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const editPrice = (service) => {
        if(service.type !== "kg"){
            return;
        }
        setPriceName(service.name);
        setPriceId(service.id);
        const initialPricesState = {};
        states.forEach(state => {
            const priceObj = service.prices.find(price => price.state === state.id);
            initialPricesState[state.id] = priceObj ? priceObj.price : 0;
        });
        setPrices(initialPricesState);
        setPriceModal(true);
    }
    const handlePriceChange = (stateId, newPrice) => {
        setPrices(prevPrices => ({
            ...prevPrices,
            [stateId]: newPrice
        }));
    };
    const closePriceModal = () =>{
        if(isSubmittingPrice){
            return;
        }
        setPriceModal(false);
    }

    const addService = () =>{
        setServiceId(null);
        setServiceName('');
        setServiceLogo('');
        setServiceType('kg');
        setServiceUnit('');
        setServiceStatus(false);
        setServiceModal(true);
    }

    const editService = (service) =>{
        setServiceId(service.id);
        setServiceName(service.name);
        setServiceLogo(service.logo);
        setServiceType(service.type);
        setServiceUnit(service.unit);
        setServiceStatus(service.status);
        setServiceModal(true);
    }

    const closeServiceModal = () =>{
        if(isSubmitting){
            return;
        }
        setServiceModal(false);
    }

    const submitService = async() =>{
        let endpoint = "service/add";
        if(serviceId){
            endpoint = "service/edit/"+serviceId;
        }
        if(serviceName.trim() === "" || serviceLogo === "" || serviceUnit === ""){
            return;
        }
        let obj = {
            name: serviceName.trim(),
            logo: serviceLogo,
            type: serviceType,
            unit: serviceUnit,
            status: serviceStatus
        }

        setIsSubmitting(true);
        const resp = await postData(endpoint, obj);
        if(resp){
            setServiceModal(false);
            getOneData();
        }
        setIsSubmitting(false);
    }

    const submitPrice = async(e) =>{
        e.preventDefault();
        setIsSubmittingPrice(true);
        const priceData = states.map(state => ({
            state: state.id,
            price: prices[state.id]
        }));
        const resp = await postData('/service/price/'+priceId, {prices: priceData});
        if(resp){
            setPriceModal(false);
            getOneData();
        }
        setIsSubmittingPrice(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Services</Typography>
                <Button variant='contained' onClick={addService} >Add Service</Button>
            </Stack>

            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='20%' paddingX={1}>
                    <Typography><b>Name</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Logo</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Type</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Unit</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Price</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Status</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Categories</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Items</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Edit</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 220px)' overflow='auto' gap={1}>
                {serviceList.map((service, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='20%' paddingX={1}>
                            <Typography>{service.name}</Typography>
                        </Box>
                        <Box width='10%'>
                            <img src={service.logo} width={'100px'} height={'100px'} alt='Logo' />
                        </Box>
                        <Box width='10%'>
                            <Typography>{service.type.toUpperCase()}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{service.unit}</Typography>
                        </Box>
                        <Box width='10%'>
                            {service.type === "kg" && <Button variant='contained' onClick={()=>{editPrice(service)}} >Prices</Button>}
                        </Box>
                        <Box width='10%'>
                            {service.status && <Typography color={'green'}>Active</Typography>}
                            {!service.status && <Typography color={'red'}>Inactive</Typography>}
                        </Box>
                        <Box width='10%'>
                            <Link to={'/category/'+service.id}>
                                <Button variant='contained'>Category</Button>
                            </Link>
                        </Box>
                        <Box width='10%'>
                            {service.type === "kg" && <Link to={'/cloth/'+service.id}>
                                <Button variant='contained'>Cloths</Button>
                            </Link>}
                            {service.type !== "kg" && <Link to={'/clothitem/'+service.id}>
                                <Button variant='contained'>Cloths</Button>
                            </Link>}
                        </Box>
                        <Box width='10%'>
                            <Button variant='contained' color='error' onClick={()=>{editService(service)}} >Edit</Button>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Services</Typography>
                </Stack>}
                {serviceList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Services to show</Typography>
                </Stack>}
            </Stack>

            <Modal open={serviceModal} onClose={closeServiceModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{serviceId?'Edit':'Add'} Service</Typography>
                        <TextField label="Service Name" value={serviceName} onChange={(e)=>{setServiceName(e.target.value)}} variant="outlined" fullWidth/>

                        <TextField label="Service Logo" type='url' value={serviceLogo} onChange={(e)=>{setServiceLogo(e.target.value)}} variant="outlined" fullWidth/>

                        <FormControl fullWidth>
                            <InputLabel>Service Type</InputLabel>
                            <Select value={serviceType} onChange={(e)=>{setServiceType(e.target.value)}} variant="outlined" disabled={serviceId?true:false}>
                                <MenuItem value={'kg'}>KG</MenuItem>
                                <MenuItem value={'size'}>Size</MenuItem>
                                <MenuItem value={'item'}>Item</MenuItem>
                            </Select>
                        </FormControl>

                        {serviceType === "kg" && <TextField label="Service Unit" value={serviceUnit} onChange={(e)=>{setServiceUnit(e.target.value)}} variant="outlined" fullWidth/>}

                        <FormControl fullWidth>
                            <InputLabel>Service Status</InputLabel>
                            <Select value={serviceStatus} onChange={(e)=>{setServiceStatus(e.target.value)}} variant="outlined">
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>

                        <LoadingButton fullWidth onClick={submitService} variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<CleaningServices/>}><span>{serviceId?'Update':'Add'} Service</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={priceModal} onClose={closePriceModal}>
                <Box component="form" sx={{position: 'absolute',top: '20%', bottom: '20%', overflowY: 'auto', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitPrice}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{priceName} Prices</Typography>
                        
                        {states.map(state => (
                            <Stack key={state.id}>
                                <Typography>{state.name}</Typography>    
                                <TextField type='number' value={prices[state.id]} onChange={(e)=>{handlePriceChange(state.id, Number(e.target.value))}} variant="outlined" fullWidth required/>
                            </Stack>
                        ))}

                        <LoadingButton fullWidth type='submit' variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<LocalAtm/>}><span>Update Price</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default ServicePage