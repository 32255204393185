import { Box } from "@mui/material"

const WhiteColor = () =>{
    return (
        <Box sx={{backgroundColor: '#fff', border: '0.5px solid #e4e4e7', width: '15px', height: '15px', borderRadius: 10}}></Box>
    )
}

const BlackColor = () =>{
    return (
        <Box sx={{backgroundColor: '#000', border: '0.5px solid #e4e4e7', width: '15px', height: '15px', borderRadius: 10}}></Box>
    )
}

const ColoredColor = () =>{
    return (
        <Box sx={{background: 'linear-gradient(to right,red,orange,yellow,green,blue,indigo,violet)', border: '0.5px solid #e4e4e7', width: '15px', height: '15px', borderRadius: 10}}></Box>
    )
}

export {WhiteColor, BlackColor, ColoredColor}