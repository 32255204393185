import { Box, Button, Grid, Modal, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { DateRangePicker } from 'rsuite';
import { useCommonUI } from '../context/UI';
import { apiUrl } from '../store/Common';

function ReportPage() {

    const {openSnackbar} = useCommonUI();
    const {afterToday} = DateRangePicker;
    const [selectedDate, setSelectedDate] = useState(null);
    const [clothModal, setClothModal] = useState(false);

    const clothSoldReport = () =>{
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].getTime()
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }else{
            openSnackbar("Select Date", "error");
            return false;
        }
        setClothModal(false);
        let url = `${apiUrl}report/clothssold?session=${localStorage.getItem('session')}&start=${start}&end=${end}`;
        window.open(url, '_blank');
    }

    const orderReport = () =>{
        let url = `${apiUrl}report/lastorder?session=${localStorage.getItem('session')}`;
        window.open(url, '_blank');
    }
    const subsReport = () =>{
        let url = `${apiUrl}report/lastsubs?session=${localStorage.getItem('session')}`;
        window.open(url, '_blank');
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Reports</Typography>
            </Stack>

            <Grid container mt={3}>
                <Grid item xs={12} lg={3} p={2}>
                    <Box sx={{background: '#fff', borderRadius: 5, boxShadow: '5px 3px 5px #00000005', textAlign: 'center', cursor: 'pointer'}} py={3} onClick={orderReport}>
                        <Typography variant='h6' textAlign={'center'}>Last Orders of Customers</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={3} p={2}>
                    <Box sx={{background: '#fff', borderRadius: 5, boxShadow: '5px 3px 5px #00000005', textAlign: 'center', cursor: 'pointer'}} py={3} onClick={subsReport}>
                        <Typography variant='h6' textAlign={'center'}>Expired Subscriptions of Customers</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={3} p={2}>
                    <Box sx={{background: '#fff', borderRadius: 5, boxShadow: '5px 3px 5px #00000005', textAlign: 'center', cursor: 'pointer'}} py={3} onClick={()=>{setClothModal(true)}}>
                        <Typography variant='h6' textAlign={'center'}>Cloth Items Sold</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Modal open={clothModal} onClose={()=>{setClothModal(false)}}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} textAlign={'center'}>
                    <Typography variant="h5">Select Date Range</Typography>
                    <Box width={'100%'} my={3}>
                        <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                    </Box>
                    <Button variant='contained' onClick={clothSoldReport}>Download</Button>
                </Box>
            </Modal>
        </Stack>
    )
}

export default ReportPage