import React, { useCallback, useEffect, useRef, useState } from 'react'
import { currency, useApiRequest } from '../store/Common';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { Close, PriceCheck, SearchTwoTone } from '@mui/icons-material';
import { useCommonUI } from '../context/UI';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';

function ExpensePage() {

    const {fetchData, postData} = useApiRequest();
    const {openSnackbar,openDialog} = useCommonUI();
    const {afterToday} = DateRangePicker;
    const [expenseList, setExpenseList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [page, setPage] = useState(2);

    const [expenseModalOpen, setExpenseModalOpen] = useState(false);
    const [expenseName, setExpenseName] = useState('');
    const [expensePrice, setExpensePrice] = useState('');
    const [expenseStore, setExpenseStore] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [totalDocs, setTotalDocs] = useState(0);
    const [bodyData, setBodyData] = useState({store: [], start: 0, end: 0});
    const [selectedDate, setSelectedDate] = useState(null);
    
    const [stores, setStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState([]);

    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('expense/lists/'+page, body);
        if(resp){
            setExpenseList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData])

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setExpenseList([]);
            const resp = await postData('expense/lists/1', {store: [], start: 0, end: 0});
            const resp1 = await fetchData('store/list');
            if(resp1){
                setStores(resp1.data);
            }
            if(resp){
                setExpenseList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[fetchData, postData]);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && expenseList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, expenseList.length, page, bodyData])

    const handleStore = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedStores(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const resetList = () =>{
        if(isLoading){
            return;
        }
        setBodyData({store: [], start: 0, end: 0});
        setSelectedStores([]);
        setSelectedDate(null);
        setExpenseList([]);
        getData(1, {store: [], start: 0, end: 0});
    }

    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].getTime()
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            store: selectedStores,
            start,
            end
        }
        setBodyData(body);
        setExpenseList([]);
        getData(1, body);
    }
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const closeModal = () =>{
        if(isSubmitting){
            return;
        }
        setExpenseModalOpen(false);
    }
    const addExpense = async() =>{
        if(expenseName.trim() === "" || expensePrice.trim() === "" || !expenseStore){
            return;
        }
        setIsSubmitting(true);
        const resp = await postData('expense/add', {name: expenseName.trim(), price: expensePrice.trim(), store: expenseStore});
        if(resp){
            setExpenseName('');
            setExpensePrice('');
            setExpenseStore();
            let newExp = resp.data;
            if(page){
                setExpenseList((prevArray) => {
                    const updatedArray = [newExp, ...prevArray.slice(0, prevArray.length - 1)];
                    return updatedArray;
                });
            }else{
                setExpenseList((prevArray) => [newExp, ...prevArray]);
            }
            setTotalDocs(totalDocs+1);
            setExpenseModalOpen(false);
        }
        setIsSubmitting(false);
    }

    const deleteExpense = (item) =>{
        if(isDeleting){
            openSnackbar("Wait for delete to complete", "warning");
            return;
        }
        openDialog("Delete Expense?",item.name, confirmDelete, item.id);
    }

    const confirmDelete = async(id) =>{
        const tid = toast.loading('Deleting Expense',{position:'bottom-right'});
        setIsDeleting(true);
        const resp = await fetchData('expense/delete/'+id);
        if(resp){
            toast.update(tid, { render: "Expense Deleted", type: "success", isLoading: false, autoClose: 2000 });
            setExpenseList([]);
            getData(1);
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Expenses ({totalDocs})</Typography>
                <Button variant='contained' onClick={()=>{setExpenseModalOpen(true)}}>Add Expense</Button>
            </Stack>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                <Stack flexDirection='row' gap={2}>
                    <Box width={400}>
                        <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                    </Box>
                    <FormControl fullWidth>
                        <InputLabel>Store</InputLabel>
                        <Select multiple value={selectedStores} onChange={handleStore} input={<OutlinedInput label="Chip" />} >
                            {stores.map(store=>{
                                return <MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                    <IconButton type='reset'>
                        <Close fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='15%'>
                    <Typography><b>Created</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Store</b></Typography>
                </Box>
                <Box width='55%'>
                    <Typography><b>Expense Details</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Amount</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Delete</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={1} ref={containerRef}>
                {expenseList.map((expense, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='15%'>
                            <Typography>{moment(expense.created).format('Do MMMM YYYY h:mm a')}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{expense.store.name}</Typography>
                        </Box>
                        <Box width='55%'>
                            <Typography>{expense.name}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{currency} {expense.price}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Button variant='contained' color='error' onClick={()=>{deleteExpense(expense)}}>Delete</Button>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Expenses</Typography>
                </Stack>}
                {expenseList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Expense to show</Typography>
                </Stack>}
            </Stack>

            <Modal open={expenseModalOpen} onClose={closeModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add Expense</Typography>
                        <TextField label="Expense Details" value={expenseName} onChange={(e)=>{setExpenseName(e.target.value)}} variant="outlined" fullWidth/>
                        <TextField label="Expense Amount" type='number' value={expensePrice} onChange={(e)=>{setExpensePrice(e.target.value)}} variant="outlined" fullWidth/>
                        <FormControl fullWidth>
                            <InputLabel>Store</InputLabel>
                            <Select value={expenseStore} onChange={(e)=>{setExpenseStore(e.target.value)}} variant="outlined">
                                {stores.map((store) => {
                                    return <MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <LoadingButton fullWidth onClick={addExpense} variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<PriceCheck/>}><span>Add Expense</span></LoadingButton>
                    </Stack>
                </Box>
           </Modal>

        </Stack>
    )
}

export default ExpensePage