import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { countryCode, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import { LoadingButton } from '@mui/lab';
import { Handshake } from '@mui/icons-material';
import moment from 'moment';
import { useCommonUI } from '../context/UI';
import { Link } from 'react-router-dom';

function PartnerPage() {

    const {fetchData, postData} = useApiRequest();
    const {openSessions} = useCommonUI();
    const [partnerList, setPartnerList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);

    const [partnerModalOpen, setPartnerModalOpen] = useState(false);
    const [newPartner, setNewPartner] = useState({ name: '', phone: '', address: '', city: '', state: '', pincode: '', type: 'hotel', latitude: '', longitude: '', share: 0, status: true });

    const [partnerId, setPartnerId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setPartnerList([]);
        const resp = await fetchData('partner/list');
        const resp1 = await fetchData('app/states');
        if(resp){
            setPartnerList(resp.data);
        }
        if(resp1){
            setStates(resp1.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const addPartner = () =>{
        setPartnerId(null);
        setNewPartner({ name: '', phone: '', address: '', city: '', state: '', pincode: '', type: 'hotel', latitude: '', longitude: '', share: 0, status: true });
        setPartnerModalOpen(true);
    }

    const editPartner = (partner) =>{
        setNewPartner({ name: partner.name, phone: partner.phone, address: partner.address, city: partner.city, state: partner.state.id, pincode: partner.pincode, type: partner.type, latitude: partner.location.latitude, longitude: partner.location.longitude, share: partner.share, status: partner.status });
        setPartnerId(partner.id);
        setPartnerModalOpen(true);
    }

    const submitPartner = async(e) =>{
        e.preventDefault();
        let endpoint = "partner/add";
        if(partnerId){
            endpoint = "partner/edit/"+partnerId;
        }

        let obj = {
            ...newPartner, 
            location: {
                latitude: newPartner.latitude,
                longitude: newPartner.longitude
            }
        }

        setIsSubmitting(true);
        const resp = await postData(endpoint, obj);
        if(resp){
            setPartnerModalOpen(false);
            getOneData();
        }
        setIsSubmitting(false);
    }

    const manageSession = (partner) => {
        openSessions(partner.id, "partner");
    }

    const handleNewPartner = (e) => {
        const { name, value } = e.target;
        setNewPartner({ ...newPartner, [name]: value });
    };

    const handlePartnerModalClose = () =>{
        if(isSubmitting){
            return;
        }
        setPartnerModalOpen(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Partners</Typography>
                <Button variant='contained' onClick={addPartner} >Add Partner</Button>
            </Stack>

            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='10%'>
                    <Typography><b>Joined</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Type</b></Typography>
                </Box>
                <Box width='10%' paddingX={1}>
                    <Typography><b>Name</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Phone</b></Typography>
                </Box>
                <Box width='13%' paddingX={1}>
                    <Typography><b>Address</b></Typography>
                </Box>
                <Box width='8%'>
                    <Typography><b>City</b></Typography>
                </Box>
                <Box width='8%'>
                    <Typography><b>State</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Pincode</b></Typography>
                </Box>
                <Box width='4%'>
                    <Typography><b>Share</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Status</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Sessions</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Edit</b></Typography>
                </Box>
                <Box width='7%'>
                    <Typography><b>Customers</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Orders</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 220px)' overflow='auto' gap={1}>
                {partnerList.map((partner, index)=>{
                    return <Stack key={index} sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}} pb={1.2}>
                        <Stack flexDirection='row' p={1.2} pb={0} alignItems='center' width='100%' >
                            <Box width='10%'>
                                <Typography>{moment(partner.createdAt).format('Do MMM YYYY h:mm a')}</Typography>
                            </Box>
                            <Box width='5%'>
                                <Typography>{partner.type.toUpperCase()}</Typography>
                            </Box>
                            <Box width='10%' paddingX={1}>
                                <Typography>{partner.name}</Typography>
                            </Box>
                            <Box width='10%'>
                                <Typography>{partner.phone}</Typography>
                            </Box>
                            <Box width='13%' paddingX={1}>
                                <Typography>{partner.address}</Typography>
                            </Box>
                            <Box width='8%'>
                                <Typography>{partner.city}</Typography>
                            </Box>
                            <Box width='8%'>
                                <Typography>{partner.state.name}</Typography>
                            </Box>
                            <Box width='5%'>
                                <Typography>{partner.pincode}</Typography>
                            </Box>
                            <Box width='4%'>
                                <Typography>{partner.share} %</Typography>
                            </Box>
                            <Box width='5%'>
                                {partner.status && <Typography color={'green'}>Active</Typography>}
                                {!partner.status && <Typography color={'red'}>Inactive</Typography>}
                            </Box>
                            <Box width='5%'>
                                <Button variant='contained' color='warning' onClick={()=>{manageSession(partner)}}>Manage</Button>
                            </Box>
                            <Box width='5%'>
                                <Button variant='contained' color='error' onClick={()=>{editPartner(partner)}} >Edit</Button>
                            </Box>
                            <Box width='7%'>
                                {partner.type === "society" && <Link to={"/customer/"+partner.id}>
                                    <Button variant='contained'>Customers</Button>
                                </Link>}
                            </Box>
                            <Box width='5%'>
                                <Link to={"/partner/"+partner.id}>
                                    <Button variant='contained'>Orders</Button>
                                </Link>
                            </Box>
                        </Stack>
                        {partner.type === "society" && <Box px={1.2} pt={0.5}>
                            <Typography><b>Refer Code: </b>{partner.referCode}</Typography>
                        </Box>}
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Partners</Typography>
                </Stack>}
                {partnerList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Partners to show</Typography>
                </Stack>}
            </Stack>

           <Modal open={partnerModalOpen} onClose={handlePartnerModalClose}>
                <Box component="form" sx={{position: 'absolute',top: '10%', bottom: '10%', overflowY: 'auto', left: 'calc(50% - 250px)', width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitPartner}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{partnerId?"Edit":"Add"} Partner</Typography>
                        <TextField label="Name" name='name' value={newPartner.name} onChange={handleNewPartner} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={newPartner.phone} onChange={handleNewPartner} variant="outlined" fullWidth required InputProps={{inputProps: {min: 0}, startAdornment: <InputAdornment position='start'>{countryCode}</InputAdornment>}}/>
                        <TextField label="Address" name='address' value={newPartner.address} onChange={handleNewPartner} variant="outlined" fullWidth multiline rows={4} required/>
                        <TextField label="City" name='city' value={newPartner.city} onChange={handleNewPartner} variant="outlined" fullWidth required/>
                        <FormControl fullWidth required>
                            <InputLabel>State</InputLabel>
                            <Select name="state" value={newPartner.state} onChange={handleNewPartner} variant="outlined">
                                {states.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField label="Pincode" name='pincode' type='number' value={newPartner.pincode} onChange={handleNewPartner} variant="outlined" fullWidth required/>

                        <TextField label="Latitude" name='latitude' type='number' value={newPartner.latitude} onChange={handleNewPartner} variant="outlined" fullWidth required/>
                        <TextField label="Longitude" name='longitude' type='number' value={newPartner.longitude} onChange={handleNewPartner} variant="outlined" fullWidth required/>

                        <FormControl fullWidth required>
                            <InputLabel>Partner Type</InputLabel>
                            <Select name='type' value={newPartner.type} onChange={handleNewPartner} variant="outlined" disabled={partnerId?true:false}>
                                <MenuItem value={'hotel'}>Hotel</MenuItem>
                                <MenuItem value={'society'}>Society</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField label="Percentage Share" name='share' type='number' value={newPartner.share} onChange={handleNewPartner} variant="outlined" fullWidth required/>

                        <FormControl fullWidth required>
                            <InputLabel>Partner Status</InputLabel>
                            <Select name='status' value={newPartner.status} onChange={handleNewPartner} variant="outlined">
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>

                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<Handshake/>}><span>{partnerId?"Update":"Add"} Partner</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default PartnerPage