import React, { useEffect, useState } from 'react'
import { useApiRequest } from '../store/Common';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

function ActiveJobs() {

    const {fetchData} = useApiRequest();
    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        const getData = async() =>{
            setIsLoading(true);
            const resp = await fetchData('schedule/jobs');
            if(resp){
                setJobs(resp.data);
            }
            setIsLoading(false);
        }
        getData();
    },[fetchData])

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Active Jobs</Typography>
            </Stack>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='10%'>
                    <Typography><b>Rider</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Store</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Type</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Completed</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Order ID</b></Typography>
                </Box>
                <Box width='25%'>
                    <Typography><b>Customer Name</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Customer Phone</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Details</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 220px)' overflow='auto' gap={1}>

                {jobs.map(rider=>{
                    return rider.activeJobs.map(job=>{
                        let oName = '';
                        let oPhone = '';
                        if(job.orderId.user.name !== job.orderId.address.name){
                            oName = ` (${job.orderId.address.name})`;
                        }
                        if(job.orderId.user.phone !== job.orderId.address.phone){
                            oPhone = ` (${job.orderId.address.phone})`;
                        }
                        return <Stack key={job._id} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='10%'>
                            <Typography>{rider.name}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{rider.store.name}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{job.type === "P"?"Pickup":"Delivery"}</Typography>
                        </Box>
                        <Box width='10%'>
                            {job.completed?(<Typography sx={{color: 'green'}}>Yes</Typography>):(<Typography sx={{color: 'red'}}>No</Typography>)}
                        </Box>
                        <Box width='10%'>
                            {job.orderId.orderId && <Typography>{job.orderId.orderId}</Typography>}
                        </Box>
                        <Box width='25%'>
                            <Link to={`/customers/${job.orderId.user.id}`}>
                                <Typography color={'rgb(71, 85, 105)'}>{job.orderId.user.name}{oName}</Typography>
                            </Link>
                        </Box>
                        <Box width='15%'>
                            <Typography>{job.orderId.user.phone}{oPhone}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Link to={`/orders/${job.orderId.id}`}>
                                <Button variant='contained' size='small'>Details</Button>
                            </Link>
                        </Box>
                    </Stack>
                    })
                })}

                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Active Jobs</Typography>
                </Stack>}
            </Stack>
        </Stack>
    )
}

export default ActiveJobs