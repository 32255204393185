import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useCommonUI } from '../context/UI';
import { useApiRequest } from '../store/Common';
import { AddHome, ManageSearch } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { toast } from 'react-toastify';

function PickupPage() {

    const {fetchData, postData} = useApiRequest();
    const {openSnackbar} = useCommonUI();
    const [states, setStates] = useState([]);

    const [inputPhone, setInputPhone] = useState('');
    const [initialLoad, setInitialLoad] = useState(false);

    const [isNewCustomer, setIsNewCustomer] = useState(false);
    const [customerId, setCustomerId] = useState(null);
    const [customerName, setCustomerName] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [addressList, setAddressList] = useState([]);

    const [selectAddressModalOpen, setSelectAddressModalOpen] = useState(false);
    const [addressModalOpen, setAddressModalOpen] = useState(false);
    const [newAddress, setNewAddress] = useState({ name: '', phone: '', address: '', city: '', state: '', pincode: '' });
    const [isSubmittingAddress, setIsSubmittingAddress] = useState(false);

    const [isScheduling, setIsScheduling] = useState(false);
    const [selectedDate, setSelectedDate] = useState({id:-1});
    const [selectedTime, setSelectedTime] = useState({id:-2});
    const [dateList, setDateList] = useState([]);
    const [timeList, setTimeList] = useState([]);

    useEffect(()=>{
        const getStates = async() =>{
            const resp = await fetchData('app/states');
            if(resp){
                setStates(resp.data);
            }
        }
        getStates();
    },[fetchData]);

    const getPhoneData = async() =>{
        if(isScheduling){
            return;
        }
        setInitialLoad(false);
        setSelectedAddress(null);
        setCustomerName('');
        setCustomerPhone('');
        setCustomerId(null);
        const regex = /^\d{10}$/;
        if(!regex.test(inputPhone)){
            openSnackbar('Phone Number Incorrect', 'error');
            return;
        }
        const resp = await postData('pickup/phone', {phone: inputPhone});
        if(resp){
            setCustomerName(resp.name);
            setCustomerPhone(inputPhone);
            setIsNewCustomer(resp.newCustomer);
            setAddressList(resp.addresses);
            setCustomerId(resp.userid);
            let addressList = resp.addresses;
            if(addressList.length > 0){
                const defaultAddress = addressList.find(item => item.default);
                const selectedAddr = defaultAddress || addressList[0];
                setSelectedAddress(selectedAddr);
            }
            setInitialLoad(true);
        }
    }

    const custAddAddress = () =>{
        setNewAddress({ name: customerName, phone: customerPhone, address: '', city: '', state: '', pincode: '' })
        setAddressModalOpen(true);
    }
    const handleSelectAddress = (addr) =>{
        setSelectedAddress(addr);
        setSelectAddressModalOpen(false);
    }
    const handleNewAddress = (e) => {
        const { name, value } = e.target;
        setNewAddress({ ...newAddress, [name]: value });
    };
    const handleAddressModalClose = () =>{
        if(isSubmittingAddress){
            return;
        }
        setAddressModalOpen(false);
    }
    const submitAddress = async(e) => {
        e.preventDefault();
        let endpoint;
        if(isNewCustomer){
            endpoint = 'pickup/address/new'
        }else{
            endpoint = 'pickup/address'
        }
        setIsSubmittingAddress(true);
        const resp = await postData(endpoint, {...newAddress, userid: customerId});
        if(resp){
            setAddressList(resp.addresses);
            let addressList = resp.addresses;
            setSelectedAddress(addressList[addressList.length - 1]);
            if(isNewCustomer){
                setCustomerName(resp.name);
                setCustomerPhone(resp.phone);
                setCustomerId(resp.userid);
                setIsNewCustomer(false);
            }
            setAddressModalOpen(false);
        }
        setIsSubmittingAddress(false);
    }

    useEffect(()=>{
        const getDayInfo = (offset) => {
            const date = moment().add(offset, 'days');
            let name = date.format('dddd');
            if(offset === 0){
                name = "Today"
            }
            if(offset === 1){
                name = "Tomorrow"
            }
            return {
                id: offset.toString(),
                name: name.toUpperCase(), 
                date: date.format('DD MMM'),
                year: date.format('Y')
            };
        };
        const generateData = () => {
            const data = [];
            const currentHour = moment().hour();
            for (let i = 0; i <= 7; i++) {
                if (i===0 && 19 < currentHour){ 
                    continue;
                }
                if (i===7 && 20 > currentHour){ 
                    continue;
                }
                data.push(getDayInfo(i));
            }
            setDateList(data);
        };
        generateData();
    },[])

    useEffect(()=>{
        const generateTimeData = () => {
            const data = [];
            const currentHour = moment().hour();
        
            for (let i = 8; i <= 20; i++) {
                if (selectedDate.id === '0' && i <= currentHour){ 
                    continue;
                }
                const time = moment().startOf('day').add(i, 'hours');
                const time2 = moment().startOf('day').add(i+1, 'hours');
                data.push({
                    id: i.toString(),
                    time: time.format('h A')+" - "+time2.format('h A'),
                });
            }
            setTimeList(data);
        };
        generateTimeData();
    },[selectedDate]);

    const datePress = (item) =>{
        setSelectedDate(item);
        setSelectedTime({id: -2});
    }
    const timePress =  (item) => {
        if(selectedDate.id !== -1){
            setSelectedTime(item)
        }
    }

    const scheduleOrder = async() =>{
        if(selectedDate.id === -1 || selectedTime.id === -2 || !selectedAddress || !customerId){
            openSnackbar('Missing Something', "error");
            return;
        }
        setIsScheduling(true);
        const tid = toast.loading('Scheduling Order',{position:'bottom-right'});
        const dateObject = moment(`${selectedDate.date} ${selectedDate.year} ${selectedTime.time}`, "DD MMM YYYY h A");
        const epochTime = dateObject.unix()*1000;
        let postdata = {
            address: selectedAddress._id,
            date: epochTime,
            userid: customerId
        };
        const resp = await postData('pickup/schedule', postdata);
        if(resp){
            setSelectedDate({id:-1})
            setSelectedTime({id:-2})
            setInputPhone('');
            setInitialLoad(false);
            setSelectedAddress(null);
            setCustomerName('');
            setCustomerPhone('');
            setCustomerId(null);
            setAddressList([]);
            toast.update(tid, { render: "Order Scheduled", type: "success", isLoading: false, autoClose: 2000 });
        }else{
            toast.dismiss();
        }
        setIsScheduling(false);
    }

    return (
        <Stack sx={{width: '100%', height: '100%', padding: 2, backgroundColor: '#f4f4f4'}}>
            <Stack sx={{flexDirection: 'row', gap: 2}}>
                <Stack sx={{background: '#fff', padding: 2, borderRadius: 5, flex: 1}}>
                    <Stack sx={{flexDirection: 'row', gap: 1, alignItems: 'center'}}>
                        <TextField variant='outlined' label="Phone Number" type='number' sx={{flex: 1}} value={inputPhone} onChange={(e)=>{setInputPhone(e.target.value)}} onBlur={getPhoneData} />
                        <IconButton color='primary' size='small' onClick={getPhoneData}><ManageSearch fontSize='large'/></IconButton>
                    </Stack>
                    <Typography variant='h6' sx={{marginTop: 2}}><b>Customer Name: </b>{customerName}</Typography>
                    <Typography variant='h6'><b>Customer Phone: </b>{customerPhone}</Typography>
                </Stack>
                <Stack sx={{background: '#fff', padding: 2, borderRadius: 5, gap: 2, flex: 1}}>
                    <Typography variant='body'><b>Pickup Address</b></Typography>
                    {selectedAddress && <Stack>
                        <Typography variant='body'>{selectedAddress.name}</Typography>
                        <Typography variant='body'>{selectedAddress.phone}</Typography>
                        {selectedAddress.address.split('\n').map((line, index)=>{
                            return <Typography key={`${selectedAddress._id}_${index}`} variant='body'>{line}</Typography>
                        })}
                        <Typography variant='body'>{selectedAddress.city}, {selectedAddress.state.name} - {selectedAddress.pincode}</Typography>
                    </Stack>}
                    {initialLoad && isNewCustomer && <Stack  sx={{flexDirection: 'row'}}><Button variant='contained' size='small' onClick={custAddAddress}>Add New Address</Button></Stack>}
                    {initialLoad && !isNewCustomer && <Stack sx={{flexDirection: 'row', gap: 1}}>
                        <Button variant='contained' size='small' onClick={()=>{setSelectAddressModalOpen(true)}}>Change Address</Button>
                        <Button variant='contained' size='small' color='warning' onClick={custAddAddress}>Add Address</Button>
                    </Stack>}
                </Stack>
            </Stack>

            <Stack my={2} p={2} sx={{background: '#fff', borderRadius: 5}}>
                <Typography variant='h5' fontWeight={'bold'}>Schedule Time</Typography>
                <Typography fontWeight={600} mt={2} mb={1}>Select Date</Typography>
                <Stack sx={{flexDirection: 'row', gap: 4}}>
                    {dateList.map(item=>{
                        const isSelected = item.id === selectedDate.id;
                        return <Stack onClick={()=>{datePress(item)}} key={item.id} sx={{backgroundColor: isSelected?'#d9166f':'#eee', width: '150px', borderRadius: 5, padding: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', color: isSelected?'#fff':'#000'}}>
                            <Typography>{item.name}</Typography>
                            <Typography variant='h6' fontWeight={600}>{item.date}</Typography>
                        </Stack>
                    })}
                </Stack>
                <Typography fontWeight={600} mt={2} mb={1}>Select Time</Typography>
                <Stack sx={{flexDirection: 'row', gap: 2, flexWrap: 'wrap'}}>
                    {timeList.map(item=>{
                        const isSelected = item.id === selectedTime.id;
                        return <Stack onClick={()=>{timePress(item)}} key={item.id} sx={{backgroundColor: isSelected?'#d9166f':'#eee', width: '170px', borderRadius: 5, padding: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', color: isSelected?'#fff':'#000'}}>
                            <Typography fontWeight={600}>{item.time}</Typography>
                        </Stack>
                    })}
                </Stack>
            </Stack>
            <Stack sx={{alignItems:'center'}} mt={2}>
                <Button variant='contained' sx={{width: 500}} onClick={scheduleOrder}>Schedule Order</Button>
            </Stack>

            <Modal open={selectAddressModalOpen} onClose={()=>{setSelectAddressModalOpen(false)}}>
                <Box sx={{position: 'absolute',top: '10%', maxHeight:'80vh', overflowY:'auto', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Typography variant='h6' mb={2}>Select Address</Typography>
                    <Stack gap={3}>
                        {addressList.map((addr)=>{
                            return <Stack key={addr._id} sx={{padding: 2, background: '#f1f1f1', borderRadius: 3, cursor:'pointer', '&:hover':{background: '#fdedf5'}}} onClick={()=>{handleSelectAddress(addr)}}>
                                <Typography variant='body'>{addr.name}</Typography>
                                <Typography variant='body'>{addr.phone}</Typography>
                                {addr.address.split('\n').map((line, index)=>{
                                    return <Typography key={`${addr._id}_${index}`} variant='body'>{line}</Typography>
                                })}
                                <Typography variant='body'>{addr.city}, {addr.state.name} - {addr.pincode}</Typography>
                            </Stack>
                        })}
                    </Stack>
                </Box>
            </Modal>

            <Modal open={addressModalOpen} onClose={handleAddressModalClose}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitAddress}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add New Address</Typography>
                        <TextField label="Name" name='name' value={newAddress.name} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={newAddress.phone} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <TextField label="Address" name='address' value={newAddress.address} onChange={handleNewAddress} variant="outlined" fullWidth multiline rows={4} required/>
                        <TextField label="City" name='city' value={newAddress.city} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <FormControl fullWidth required>
                            <InputLabel>State</InputLabel>
                            <Select name="state" value={newAddress.state} onChange={handleNewAddress} variant="outlined">
                                {states.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField label="Pincode" name='pincode' type='number' value={newAddress.pincode} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmittingAddress}  loadingPosition='start' startIcon={<AddHome/>}><span>Add New Address</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default PickupPage