import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { countryCode, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import { LoadingButton } from '@mui/lab';
import { Engineering } from '@mui/icons-material';
import moment from 'moment';
import { useCommonUI } from '../context/UI';
import { DateRangePicker } from 'rsuite';

function WorkerPage() {

    const {fetchData, postData} = useApiRequest();
    const {afterToday} = DateRangePicker;
    const {openSessions} = useCommonUI();
    const [workerList, setWorkerList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [stores, setStores] = useState([]);

    const [workerModal, setWorkerModal] = useState(false);
    const [workerId, setWorkerId] = useState(null);
    const [workerName, setWorkerName] = useState('');
    const [workerPhone, setWorkerPhone] = useState('');
    const [workerStore, setWorkerStore] = useState('');
    const [workerStatus, setWorkerStatus] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [reportModal, setReportModal] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);
    const [reportWorker, setReportWorker] = useState(null);
    const [reportDate, setReportDate] = useState(null);
    const [reportList, setReportList] = useState([]);
    const [totalWashing, setTotalWashing] = useState(0);
    const [totalIroning, setTotalIroning] = useState(0);
    const [totalPacking, setTotalPacking] = useState(0);
    const [totalPreparing, setTotalPreparing] = useState(0);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setWorkerList([]);
        const resp = await fetchData('worker/list');
        const resp1 = await fetchData('store/listprocessing');
        if(resp){
            setWorkerList(resp.data);
        }
        if(resp1){
            setStores(resp1.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const addWorker = () =>{
        setWorkerId(null);
        setWorkerName('');
        setWorkerPhone('');
        setWorkerStore('');
        setWorkerStatus(true);
        setWorkerModal(true);
    }

    const editWorker = (worker) =>{
        setWorkerId(worker.id);
        setWorkerName(worker.name);
        setWorkerPhone(worker.phone);
        setWorkerStore(worker.store.id);
        setWorkerStatus(worker.status);
        setWorkerModal(true);
    }

    const closeWorkerModal = () =>{
        if(isSubmitting){
            return;
        }
        setWorkerModal(false);
    }

    const submitWorker = async() =>{
        let endpoint = "worker/add";
        if(workerId){
            endpoint = "worker/edit/"+workerId;
        }
        if(workerName.trim() === "" || workerPhone === "" || workerStore === ""){
            return;
        }
        let obj = {
            name: workerName.trim(),
            phone: workerPhone,
            store: workerStore,
            status: workerStatus
        }

        setIsSubmitting(true);
        const resp = await postData(endpoint, obj);
        if(resp){
            setWorkerModal(false);
            getOneData();
        }
        setIsSubmitting(false);
    }

    const manageSession = (worker) => {
        openSessions(worker.id, "worker");
    }

    const manageReport = (worker) => {
        setReportWorker(worker);
        setReportList([]);
        setReportDate(null);
        setReportModal(true);
    }

    const closeReportModal = () =>{
        if(reportLoading){
            return;
        }
        setReportModal(false);
    }

    const getReport = async() =>{
        setTotalIroning(0);
        setTotalPacking(0);
        setTotalPreparing(0);
        setTotalWashing(0);

        let start = 0;
        let end = 0;
        if(reportDate && reportDate.length === 2){
            start = reportDate[0].getTime()
            end = reportDate[1].setHours(23, 59, 59, 999)
        }else{
            return;
        }

        setReportLoading(true);
        const resp = await postData('worker/report',{wid: reportWorker.id, start, end});
        if(resp){
            setReportList(resp.data);
            let washing = 0;
            let ironing = 0;
            let preparing = 0;
            let packing = 0;
            for(const rpr of resp.data){
                washing += rpr.washing;
                ironing += rpr.ironing;
                preparing += rpr.preparing;
                packing += rpr.packing;
            }
            setTotalIroning(ironing);
            setTotalPacking(packing);
            setTotalPreparing(preparing);
            setTotalWashing(washing);
        }
        setReportLoading(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Workers</Typography>
                <Button variant='contained' onClick={addWorker} >Add Worker</Button>
            </Stack>

            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='20%'>
                    <Typography><b>Joined</b></Typography>
                </Box>
                <Box width='20%' paddingX={1}>
                    <Typography><b>Name</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Phone</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Processing Center</b></Typography>
                </Box>
                <Box width='8%'>
                    <Typography><b>Status</b></Typography>
                </Box>
                <Box width='7%'>
                    <Typography><b>Report</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Session</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Edit</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 220px)' overflow='auto' gap={1}>
                {workerList.map((worker, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='20%'>
                            <Typography>{moment(worker.createdAt).format('Do MMM YYYY h:mm a')}</Typography>
                        </Box>
                        <Box width='20%' paddingX={1}>
                            <Typography>{worker.name}</Typography>
                        </Box>
                        <Box width='15%'>
                            <Typography>{worker.phone}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{worker.store.name}</Typography>
                        </Box>
                        <Box width='8%'>
                            {worker.status && <Typography color={'green'}>Active</Typography>}
                            {!worker.status && <Typography color={'red'}>Inactive</Typography>}
                        </Box>
                        <Box width='7%'>
                            <Button variant='contained' onClick={()=>{manageReport(worker)}}>Report</Button>
                        </Box>
                        <Box width='10%'>
                            <Button variant='contained' color='warning' onClick={()=>{manageSession(worker)}}>Manage</Button>
                        </Box>
                        <Box width='10%'>
                            <Button variant='contained' color='error' onClick={()=>{editWorker(worker)}} >Edit</Button>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Workers</Typography>
                </Stack>}
                {workerList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Workers to show</Typography>
                </Stack>}
            </Stack>

            <Modal open={workerModal} onClose={closeWorkerModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{workerId?'Edit':'Add'} Worker</Typography>
                        <TextField label="Worker Name" value={workerName} onChange={(e)=>{setWorkerName(e.target.value)}} variant="outlined" fullWidth/>

                        <TextField label="Worker Phone" type='number' value={workerPhone} onChange={(e)=>{setWorkerPhone(e.target.value)}} variant="outlined" fullWidth InputProps={{inputProps: {min: 0}, startAdornment: <InputAdornment position='start'>{countryCode}</InputAdornment>}}/>

                        <FormControl fullWidth>
                            <InputLabel>Processing Center</InputLabel>
                            <Select value={workerStore} onChange={(e)=>{setWorkerStore(e.target.value)}}>
                                {stores.map(store=>{
                                    return <MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel>Worker Status</InputLabel>
                            <Select value={workerStatus} onChange={(e)=>{setWorkerStatus(e.target.value)}} variant="outlined">
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>

                        <LoadingButton fullWidth onClick={submitWorker} variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<Engineering/>}><span>{workerId?'Update':'Add'} Worker</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={reportModal} onClose={closeReportModal}>
                <Box sx={{position: 'absolute',top: '20%', bottom: '20%', overflowY: 'auto', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    {reportWorker && <Stack>
                        <Typography variant='h6'>{reportWorker.name}</Typography>
                    </Stack>}
                    <Stack flexDirection={'row'} justifyContent={'space-between'} py={2}>
                        <Box width={350}>
                            <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={reportDate} onChange={setReportDate} />
                        </Box>
                        <Button variant='contained' onClick={getReport}>Get Report</Button>
                    </Stack>
                    {reportLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Report</Typography>
                    </Stack>}
                    {reportList.length === 0 && !reportLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='body'>No Data to show</Typography>
                    </Stack>}
                    <Stack gap={2}>
                        {reportList.map(report=>{
                            return <Stack sx={{background: '#f1f1f1'}} p={1.5} borderRadius={5}>
                                <Typography><b>Date: </b>{moment(report.date).format('Do MMM YYYY')}</Typography>
                                <Typography><b>Prepared: </b>{report.preparing}</Typography>
                                <Typography><b>Washed: </b>{report.washing}</Typography>
                                <Typography><b>Ironed: </b>{report.ironing}</Typography>
                                <Typography><b>Packed: </b>{report.packing}</Typography>
                            </Stack>
                        })}
                        {reportList.length > 0 && <Stack sx={{background: '#f1f1f1'}} p={1.5} borderRadius={5}>
                            <Typography><b>TOTAL</b></Typography>
                            <Typography><b>Prepared: {totalPreparing}</b></Typography>
                            <Typography><b>Washed: {totalWashing}</b></Typography>
                            <Typography><b>Ironed: {totalIroning}</b></Typography>
                            <Typography><b>Packed: {totalPacking}</b></Typography>
                        </Stack>}
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default WorkerPage