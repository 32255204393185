import { Box, Stack, TextField} from '@mui/material';
import { LoginSharp } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useApiRequest } from '../store/Common';
import { updateLogin, updateName, updatePhone, updateType } from '../store/slices/configSlice';

function Login(){
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLogginIn, setIsLogginIn] = useState(false);
    const [errorField, setFieldErrors] = useState({ email: '', password: '' });
    const {postData} = useApiRequest();


    const login = async() =>{
        const validationErrors = {};
        if (!username) {
            validationErrors.email = 'Phone is required';
        }

        if (!password) {
            validationErrors.password = 'Password is required';
        }
        if (Object.keys(validationErrors).length !== 0) {
            setFieldErrors(validationErrors);
            return;
        }

        setIsLogginIn(true);
        let obj = {
            phone: username,
            password
        }
        const resp = await postData('app/login', obj);
        if(resp){
            dispatch(updateName(resp.data.name));
            dispatch(updatePhone(resp.data.phone));
            dispatch(updateType(resp.data.type));
            localStorage.setItem('session', resp.data.session);
            dispatch(updateLogin(true));
        }
        setIsLogginIn(false);
    }

    return (
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
            <Stack 
                component="form"
                spacing={2}
                direction="column"
                alignItems="center"
                sx={{ width: 350, p: 4, boxShadow: 3, borderRadius: 2 }}
            >
                <img src='/assets/dryfi.png' width='200px' style={{marginBottom: 20}} alt='Dryfi'/>
                
                <TextField 
                    fullWidth
                    value={username}
                    onChange={(e)=>{setUsername(e.target.value);setFieldErrors({ ...errorField, email: '' })}}
                    label="Admin Phone Number" 
                    variant="outlined" 
                    type='number'
                    error={!!errorField.email}
                    helperText={errorField.email}
                    />
                
                <TextField 
                    fullWidth 
                    label="Password" 
                    value={password}
                    onChange={(e)=>{setPassword(e.target.value); setFieldErrors({ ...errorField, password: '' });}}
                    variant="outlined" 
                    type="password" 
                    error={!!errorField.password}
                    helperText={errorField.password}
                    />
                
                
                <LoadingButton
                    fullWidth
                    onClick={login}
                    loading={isLogginIn}
                    color='success'
                    variant="contained"
                    loadingPosition="start"
                    startIcon={<LoginSharp />}
                    >
                    <span>LOGIN</span>
                </LoadingButton>
            </Stack>
        </Box>
    );
}

export default Login;