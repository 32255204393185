import React, { useCallback, useEffect, useState } from 'react'
import { useApiRequest } from '../store/Common';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useCommonUI } from '../context/UI';

function QueuePage() {

    const {fetchData} = useApiRequest();
    const {openDialog, openSnackbar} = useCommonUI();
    const [queue, setQueue] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    
    const getData = useCallback(async() =>{
        setQueue([]);
        setIsLoading(true);
        const resp = await fetchData('schedule/queues');
        if(resp){
            setQueue(resp.data);
        }
        setIsLoading(false);
    },[fetchData])

    useEffect(()=>{
        getData();
    },[getData])

    const deleteQueue = (item) =>{
        if(isDeleting){
            openSnackbar("Wait for delete to complete", "warning");
            return;
        }
        openDialog("Delete Job from Queue?",`${item.order.user.name} by ${item.rider.name}`, confirmDelete, item.id);
    }

    const confirmDelete = async(id) =>{
        const tid = toast.loading('Deleting Queue',{position:'bottom-right'});
        setIsDeleting(true);
        const resp = await fetchData('schedule/deletequeue/'+id);
        if(resp){
            toast.update(tid, { render: "Queue Deleted", type: "success", isLoading: false, autoClose: 2000 });
            getData();
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Jobs Queue</Typography>
            </Stack>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='10%'>
                    <Typography><b>Time Slot</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Rider</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Store</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Type</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Order ID</b></Typography>
                </Box>
                <Box width='20%'>
                    <Typography><b>Customer Name</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Customer Phone</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Details</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Delete</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 220px)' overflow='auto' gap={1}>

                {queue.map(job=>{
                    return <Stack key={job.id} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='10%'>
                            <Typography>{moment(job.date).format('Do MMM YYYY h a')}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{job.rider.name}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{job.store.name}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{job.type === "P"?"Pickup":"Delivery"}</Typography>
                        </Box>
                        <Box width='10%'>
                            {job.order.orderId && <Typography>{job.order.orderId}</Typography>}
                        </Box>
                        <Box width='20%'>
                            <Link to={`/customers/${job.order.user.id}`}>
                                <Typography color={'rgb(71, 85, 105)'}>{job.order.user.name}</Typography>
                            </Link>
                        </Box>
                        <Box width='10%'>
                            <Typography>{job.order.user.phone}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Link to={`/orders/${job.order.id}`}>
                                <Button variant='contained' size='small'>Details</Button>
                            </Link>
                        </Box>
                        <Box width='10%'>
                            <Button variant='contained' color='error' onClick={()=>{deleteQueue(job)}}>Delete</Button>
                        </Box>
                    </Stack>
                })}

                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Job Queue</Typography>
                </Stack>}
            </Stack>
        </Stack>
    )
}

export default QueuePage