import { Close, PersonAdd, SearchTwoTone } from '@mui/icons-material'
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { apiUrl, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import { useCommonUI } from '../context/UI';

function CustomerPage() {

    const {fetchData, postData} = useApiRequest();
    const {openSnackbar} = useCommonUI();
    const [searchTerm, setSearchTerm] = useState('');
    const [bodyData, setBodyData] = useState({search: '', sort: ''});
    const [sort, setSort] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [page, setPage] = useState(2);

    const [addressModalOpen, setAddressModalOpen] = useState(false);
    const [newAddress, setNewAddress] = useState({ name: '', phone: '', address: '', city: '', state: '', pincode: '' });
    const [isSubmittingAddress, setIsSubmittingAddress] = useState(false);
    const [states, setStates] = useState([]);
    const [totalDocs, setTotalDocs] = useState(0);

    useEffect(()=>{
        const getStates = async() =>{
            const resp = await fetchData('app/states');
            if(resp){
                setStates(resp.data);
            }
        }
        getStates();
    },[fetchData]);

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setSearchTerm('');
        setSort('');
        setBodyData({search: '', sort: ''});
        setCustomerList([]);
        getData(1, {search: '', sort: ''});
    }

    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        setBodyData({search: searchTerm, sort: sort});
        setCustomerList([]);
        getData(1, {search: searchTerm, sort: sort});
    }

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };

    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('customer/lists/'+page, body);
        if(resp){
            setCustomerList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData])

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setCustomerList([]);
            const resp = await postData('customer/lists/1', {search: '', sort: ''});
            if(resp){
                setCustomerList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && customerList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, customerList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const handleNewAddress = (e) => {
        const { name, value } = e.target;
        setNewAddress({ ...newAddress, [name]: value });
    };

    const handleAddressModalClose = () =>{
        if(isSubmittingAddress){
            return;
        }
        setAddressModalOpen(false);
    }

    const submitAddress = async(e) => {
        e.preventDefault();
        setIsSubmittingAddress(true);
        const resp = await postData('customer/new', newAddress);
        if(resp){
            let newCust = resp.data;
            if(page){
                setCustomerList((prevArray) => {
                    const updatedArray = [newCust, ...prevArray.slice(0, prevArray.length - 1)];
                    return updatedArray;
                });
            }else{
                setCustomerList((prevArray) => [newCust, ...prevArray]);
            }
            setAddressModalOpen(false);
            setNewAddress({ name: '', phone: '', address: '', city: '', state: '', pincode: '' });
            openSnackbar("Customer Added", "success");
        }
        setIsSubmittingAddress(false);
    }

    const exportCsv = () =>{
        let body = {
            search: searchTerm, 
            sort: sort
        }
        const url = `${apiUrl}customer/csv?session=${localStorage.getItem('session')}&data=${encodeURIComponent(JSON.stringify(body))}`;
        window.open(url, '_blank');
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Customers ({totalDocs})</Typography>
                <Stack flexDirection={'row'} gap={2}>
                    <Button variant='contained' onClick={()=>{setAddressModalOpen(true)}}>Add Customer</Button>
                    <Button variant='contained' onClick={exportCsv}>Export</Button>
                </Stack>
            </Stack>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                <Stack flexDirection='row' gap={2}>
                    <TextField fullWidth variant='outlined' label='Customer Name/Phone' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                    <FormControl fullWidth>
                        <InputLabel>Sort</InputLabel>
                        <Select value={sort} onChange={(e)=>{setSort(e.target.value)}} >
                            <MenuItem value='wasc'>Wallet Ascending</MenuItem>
                            <MenuItem value='wdsc'>Wallet Descending</MenuItem>
                        </Select>
                    </FormControl>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                    <IconButton type='reset'>
                        <Close fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='20%'>
                    <Typography><b>Joined</b></Typography>
                </Box>
                <Box width='25%'>
                    <Typography><b>Customer Name</b></Typography>
                </Box>
                <Box width='25%'>
                    <Typography><b>Customer Phone</b></Typography>
                </Box>
                <Box width='20%'>
                    <Typography><b>Wallet</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Details</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={1} ref={containerRef}>
                {customerList.map((customer, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='20%'>
                            <Typography>{moment.utc(customer.createdAt).local().format('Do MMMM YYYY h:mm a')}</Typography>
                        </Box>
                        <Box width='25%'>
                            <Typography>{customer.name}</Typography>
                        </Box>
                        <Box width='25%'>
                            <Typography>{customer.phone}</Typography>
                        </Box>
                        <Box width='20%'>
                            <Typography>{customer.wallet}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Link to={`/customers/${customer.id}`}>
                                <Button variant='contained' size='small'>Details</Button>
                            </Link>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Customers</Typography>
                </Stack>}
                {customerList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Customers to show</Typography>
                </Stack>}
            </Stack>

            <Modal open={addressModalOpen} onClose={handleAddressModalClose}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitAddress}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add New Customer</Typography>
                        <TextField label="Name" name='name' value={newAddress.name} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={newAddress.phone} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <TextField label="Address" name='address' value={newAddress.address} onChange={handleNewAddress} variant="outlined" fullWidth multiline rows={4} required/>
                        <TextField label="City" name='city' value={newAddress.city} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <FormControl fullWidth required>
                            <InputLabel>State</InputLabel>
                            <Select name="state" value={newAddress.state} onChange={handleNewAddress} variant="outlined">
                                {states.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField label="Pincode" name='pincode' type='number' value={newAddress.pincode} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmittingAddress}  loadingPosition='start' startIcon={<PersonAdd/>}><span>Add New Customer</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>
        </Stack>
    )
}

export default CustomerPage