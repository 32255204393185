import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useApiRequest } from '../store/Common';
import { Checkroom } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useCommonUI } from '../context/UI';
import { toast } from 'react-toastify';

function ClothPage() {

    const {serviceId} = useParams();
    const navigate = useNavigate();
    const {openDialog} = useCommonUI();
    const {fetchData, postData} = useApiRequest();
    const [serviceData, setServiceData] = useState({name: '', id: null, type: 'kg'});
    const [categoryList, setCategoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [clothViewList, setClothViewList] = useState([]);
    const [clothList, setClothList] = useState([]);
    const [clothSearch, setClothSearch] = useState('');

    const [clothName, setClothName] = useState('');
    const [clothColor, setClothColor] = useState('none');
    const [clothCategory, setClothCategory] = useState('');
    const [clothStatus, setClothStatus] = useState(false);
    const [clothId, setClothId] = useState(false);
    const [clothModal, setClothModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setClothList([]);
        setClothViewList([]);
        setClothSearch('');
        const resp = await fetchData('service/info/'+serviceId);
        const resp1 = await fetchData('service/cloth/'+serviceId);
        if(resp && resp1){
            if(resp.data.type !== "kg"){
                navigate('/services');
            }
            setServiceData(resp.data);
            setClothList(resp1.data);
            setClothViewList(resp1.data);
        }
        const resp3 = await fetchData('service/categories/'+serviceId);
        if(resp3){
            setCategoryList(resp3.data);
        }
        setIsLoading(false);
    },[fetchData, serviceId, navigate]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const handleClothSearch = (e)=>{
        setClothSearch(e.target.value);
        let searchQuery = e.target.value;
        if(searchQuery === ""){
            setClothViewList(clothList);
        }else{
            setClothViewList(clothList.filter(item => 
                item.name.toLowerCase().includes(searchQuery.toLowerCase())
            ));
        }
    }

    const addCloth = () =>{
        setClothId(null);
        setClothName('');
        setClothColor('none');
        setClothCategory('');
        setClothStatus(false);
        setClothModal(true);
    }

    const editCloth = (cloth) => {
        setClothId(cloth.id);
        setClothName(cloth.name);
        setClothColor(cloth.color?cloth.color:'none');
        setClothCategory(cloth.category?cloth.category.id:'');
        setClothStatus(cloth.status);
        setClothModal(true);
    }
    
    const closeClothModal = () =>{
        if(isSubmitting){
            return;
        }
        setClothModal(false);
    }

    const submitCloth = async() =>{
        let endpoint = "service/cloth";
        if(clothId){
            endpoint = "service/cloth/edit/"+clothId;
        }
        if(clothName.trim() === ""){
            return;
        }
        let obj = {
            name: clothName.trim(),
            color: clothColor,
            status: clothStatus,
            category: clothCategory,
            service: serviceId,
            serviceType: serviceData.type
        }

        setIsSubmitting(true);
        const resp = await postData(endpoint, obj);
        if(resp){
            setClothModal(false);
            getOneData();
        }
        setIsSubmitting(false);
    }

    const deleteCloth = (cloth) => {
        if(isDeleting){
            return;
        }
        openDialog("Delete Cloth?","Name: "+cloth.name+"<br>Color: "+cloth.color, confirmDelete, cloth.id);
    }
    const confirmDelete = async(obj) =>{
        const tid = toast.loading('Deleting Cloth',{position:'bottom-right'});
        setIsDeleting(true);
        const resp = await postData('service/cloth/delete', {id: obj});
        if(resp){
            toast.update(tid, { render: "Cloth Deleted", type: "success", isLoading: false, autoClose: 2000 });
            getOneData();
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Cloths ({clothList.length}) in {serviceData.name}</Typography>
                <Button variant='contained' onClick={addCloth} >Add Cloth</Button>
            </Stack>
            <Box p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}}>
                <TextField fullWidth variant='outlined' label='Search' value={clothSearch} onChange={handleClothSearch} />
            </Box>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='30%' paddingX={1}>
                    <Typography><b>Name</b></Typography>
                </Box>
                <Box width='20%'>
                    <Typography><b>Color</b></Typography>
                </Box>
                <Box width='20%'>
                    <Typography><b>Category</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Status</b></Typography>
                </Box>
                <Box width='7%'>
                    <Typography><b>Edit</b></Typography>
                </Box>
                <Box width='8%'>
                    <Typography><b>Delete</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={1}>
                {clothViewList.map((cloth)=>{
                    return <Stack key={cloth.id} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='30%' paddingX={1}>
                            <Typography>{cloth.name}</Typography>
                        </Box>
                        <Box width='20%'>
                            {cloth.color && <Typography>{cloth.color.toUpperCase()}</Typography>}
                        </Box>
                        <Box width='20%'>
                            {cloth.category && <Typography>{cloth.category.name}</Typography>}
                        </Box>
                        <Box width='15%'>
                            {cloth.status && <Typography color={'green'}>Active</Typography>}
                            {!cloth.status && <Typography color={'red'}>Inactive</Typography>}
                        </Box>
                        <Box width='7%'>
                            <Button variant='contained' color='warning' onClick={()=>{editCloth(cloth)}} >Edit</Button>
                        </Box>
                        <Box width='8%'>
                            <Button variant='contained' color='error' onClick={()=>{deleteCloth(cloth)}} >Delete</Button>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Cloths</Typography>
                </Stack>}
                {clothViewList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Cloth to show</Typography>
                </Stack>}
            </Stack>

            <Modal open={clothModal} onClose={closeClothModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{clothId?'Edit':'Add'} Cloth</Typography>

                        <TextField label="Cloth Name" value={clothName} onChange={(e)=>{setClothName(e.target.value)}} variant="outlined" fullWidth/>

                        <FormControl fullWidth>
                            <InputLabel>Cloth Color</InputLabel>
                            <Select value={clothColor} onChange={(e)=>{setClothColor(e.target.value)}} variant="outlined">
                                <MenuItem value={'none'}>None</MenuItem>
                                <MenuItem value={'white'}>White</MenuItem>
                                <MenuItem value={'black'}>Black</MenuItem>
                                <MenuItem value={'color'}>Color</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel>Cloth Category</InputLabel>
                            <Select value={clothCategory} onChange={(e)=>{setClothCategory(e.target.value)}} variant="outlined">
                                {categoryList.map(category=>{
                                    return <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel>Cloth Status</InputLabel>
                            <Select value={clothStatus} onChange={(e)=>{setClothStatus(e.target.value)}} variant="outlined">
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>

                        <LoadingButton fullWidth onClick={submitCloth} variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<Checkroom/>}><span>{clothId?'Update':'Add'} Cloth</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default ClothPage